import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { motion, useInView } from 'framer-motion';
import { useClutch } from 'hooks/use-clutch';
import { useTranslatedTitle } from 'hooks/use-translated-title';

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
    margin-bottom: 3.75rem;
`;

const SWrapper = styled.div`
    overflow: hidden;
`;

const SWidgetContainer = styled.div`
    display: flex;
    justify-content: center;

    .clutch-widget {
        width: 80%;

        ${CONSTANTS.MEDIA.max1320`
            width: 86%;
        `};

        ${CONSTANTS.MEDIA.max1200`
            width: 92%;
        `}

        ${CONSTANTS.MEDIA.max1024`
            width: 100%;
        `}
    }
`;

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
`;

export const ClutchSection = () => {
    const { ref } = useClutch();
    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: true });
    const title = 'main-page.clutchSection.header';
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <div ref={ref}>
            <SContainer id="clutch">
                <SHeaderSecond
                    topParagraph="main-page.clutchSection.subheader"
                    id={kebabCaseId}
                    handleCopyUrl={handleCopyUrl}
                >
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                <SWrapper ref={containerRef}>
                    <motion.div
                        initial={{
                            x: '-100%',
                        }}
                        animate={{
                            x: isInView ? 0 : '-100%',
                        }}
                        transition={{
                            duration: 0.5,
                            delay: 0.5,
                        }}
                    >
                        <SWidgetContainer>
                            <div
                                className="clutch-widget"
                                data-url="https://widget.clutch.co"
                                data-widget-type="8"
                                data-height="375"
                                data-nofollow="true"
                                data-expandifr="true"
                                data-primary-color="#F44336"
                                data-secondary-color="#F44336"
                                data-reviews="2110632,2008968,1833378,1825214,1199849,1187228"
                                data-clutchcompany-id="138591"
                            />
                        </SWidgetContainer>
                    </motion.div>
                </SWrapper>
            </SContainer>
        </div>
    );
};
