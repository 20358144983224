import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { Container } from 'components/container';
import { HeaderFirst } from 'components/header-first';
import { Paragraph } from 'components/paragraph';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';

const SWrapper = styled.div`
    text-align: center;
    margin-top: 9.75rem;
    padding: clamp(3rem, 2.2958rem + 3.0047vw, 5rem) var(--section-clamp);
    ${CONSTANTS.MEDIA.max1024`
        margin-top: 5rem;
    `}
    background-color: var(--gray-color-90);
`;

const SHeaderFirst = styled(HeaderFirst)`
    margin-bottom: 1.5rem;
`;

const SParagraph = styled(Paragraph)`
    font-size: 1.375rem;
    margin-bottom: 3.125rem;
`;

const STopParagraph = styled(Paragraph)`
    display: block;
    color: var(--primary-color);
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 1.2px;
    margin-bottom: 1rem;
    text-align: center;
`;

export const CaseStudiesMainSection = () => {
    return (
        <Container>
            <SWrapper>
                <STopParagraph>
                    <FormattedMessage id="case-studies-page.header.title-label" />
                </STopParagraph>
                <SHeaderFirst>
                    <FormattedMessage id="case-studies-page.header.title" />
                </SHeaderFirst>
                <SParagraph>
                    <FormattedMessage id="case-studies-page.header.description" />
                </SParagraph>
                <Button to={PATHS.ESTIMATE_THE_PROJECT} component="gatsby-link">
                    <FormattedMessage id="general.estimateProject" />
                </Button>
            </SWrapper>
        </Container>
    );
};
