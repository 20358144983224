import React from 'react';
import {
    CASE_CATEGORY,
    useCaseStudiesCategory,
} from 'views/case-studies/_hooks/use-case-studies-category';
import { CaseStudiesMainSection } from 'views/case-studies/case-studies-main-section';
import { CaseStudiesSection } from 'views/case-studies/case-studies-section';
import { CaseStudiesShowcaseSection } from 'views/case-studies/case-studies-showcase-section';
import { ClutchSection } from 'views/index/clutch-section';
import { FAQAccordionSection } from 'modules/faq-accordion-section';
import { RequestSection } from 'modules/request-section';

export const CaseStudiesView = () => {
    const category = useCaseStudiesCategory();

    const FAQ_ITEMS = [
        {
            question: `case-studies-page.faq.item-1.question`,
            answer: `case-studies-page.faq.item-1.answer`,
            links: [],
        },
        {
            question: `case-studies-page.faq.item-2.question`,
            answer: `case-studies-page.faq.item-2.answer`,
            links: [],
        },
        {
            question: `case-studies-page.faq.item-3.question`,
            answer: `case-studies-page.faq.item-3.answer`,
            links: [],
        },
    ];

    return (
        <>
            <CaseStudiesMainSection />
            <CaseStudiesShowcaseSection />
            <CaseStudiesSection
                projectRangeFrom={0}
                projectRangeTo={5}
                categoryTitle={category[CASE_CATEGORY.PROPTECH].title}
                categoryImage={category[CASE_CATEGORY.PROPTECH].image}
            />
            <CaseStudiesSection
                projectRangeFrom={5}
                projectRangeTo={10}
                categoryTitle={category[CASE_CATEGORY.FINTECH].title}
                categoryImage={category[CASE_CATEGORY.FINTECH].image}
            />
            <ClutchSection />
            <CaseStudiesSection
                projectRangeFrom={10}
                projectRangeTo={12}
                categoryTitle={category[CASE_CATEGORY.BLOCKCHAIN].title}
                categoryImage={category[CASE_CATEGORY.BLOCKCHAIN].image}
            />
            <CaseStudiesSection
                projectRangeFrom={12}
                projectRangeTo={16}
                categoryTitle={
                    category[CASE_CATEGORY.MEDIA_AND_ENTERTAINMENT].title
                }
                categoryImage={
                    category[CASE_CATEGORY.MEDIA_AND_ENTERTAINMENT].image
                }
            />
            <CaseStudiesSection
                projectRangeFrom={16}
                projectRangeTo={22}
                categoryTitle={
                    category[CASE_CATEGORY.CUSTOM_SOFTWARE_HUB].title
                }
                categoryImage={
                    category[CASE_CATEGORY.CUSTOM_SOFTWARE_HUB].image
                }
            />
            <FAQAccordionSection
                data={FAQ_ITEMS}
                title="case-studies-page.faq.title"
            />
            <RequestSection />
        </>
    );
};
