import React from 'react';
import { CaseStudiesView } from 'views/case-studies';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/case-studies/index.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const CaseStudiesPage = (props) => {
    const { location } = props;
    return (
        <Layout location={location} messages={messages}>
            <CaseStudiesView />
        </Layout>
    );
};

CaseStudiesPage.propTypes = {
    location: object.isRequired,
};

export default CaseStudiesPage;

export const Head = () => <SEO tags={TAGS.CASE_STUDIES} />;
