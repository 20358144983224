import React from 'react';
import styled from 'styled-components';
import { AnimatedTechnologies } from 'components/animated-technologies';
import { Container } from 'components/container';
import { RowWithTextImg } from 'modules/row-text-img';

const SRowWithTextImage = styled(RowWithTextImg)`
    line-height: 1.75rem;
`;

export const CaseStudiesShowcaseSection = () => {
    return (
        <Container>
            <SRowWithTextImage
                descriptions={['case-studies-page.showcase.description']}
                img={<AnimatedTechnologies />}
            />
        </Container>
    );
};
