import { graphql, useStaticQuery } from 'gatsby';

export const CASE_CATEGORY = {
    PROPTECH: 'proptech',
    FINTECH: 'fintech',
    BLOCKCHAIN: 'blockchain',
    MEDIA_AND_ENTERTAINMENT: 'media-and-entertainment',
    CUSTOM_SOFTWARE_HUB: 'custom-software-hub',
};

export const useCaseStudiesCategory = () => {
    const images = useStaticQuery(graphql`
        {
            proptech: file(relativePath: { eq: "case-studies/proptech.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
            fintech: file(relativePath: { eq: "case-studies/fintech.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
            blockchain: file(
                relativePath: { eq: "case-studies/blockchain.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
            mediaAndEntertainment: file(
                relativePath: { eq: "case-studies/media-and-entertainment.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
            customSoftware: file(
                relativePath: { eq: "case-studies/custom-software.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return {
        [CASE_CATEGORY.PROPTECH]: {
            title: 'case-studies-page.categories.proptech',
            alt: 'case-studies-page.categories.proptech',
            image: images.proptech.childImageSharp.gatsbyImageData,
        },
        [CASE_CATEGORY.FINTECH]: {
            title: 'case-studies-page.categories.fintech',
            image: images.fintech.childImageSharp.gatsbyImageData,
        },
        [CASE_CATEGORY.BLOCKCHAIN]: {
            title: 'case-studies-page.categories.blockchain',
            image: images.blockchain.childImageSharp.gatsbyImageData,
        },
        [CASE_CATEGORY.MEDIA_AND_ENTERTAINMENT]: {
            title: 'case-studies-page.categories.media-and-entertainment',
            image: images.mediaAndEntertainment.childImageSharp.gatsbyImageData,
        },
        [CASE_CATEGORY.CUSTOM_SOFTWARE_HUB]: {
            title: 'case-studies-page.categories.custom-software-hub',
            image: images.customSoftware.childImageSharp.gatsbyImageData,
        },
    };
};
